<template>
  <div class="d-flex flex-row">
    <div class="flex-grow-1 ">
      <v-form ref="form" v-model="valid" lazy-validation>
      <CTextField 
       changed
        height="48px" 
        hide-details 
        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        validate-on-blur
        :type="showOldPassword ? 'text' : 'password'"
        v-model="oldPassword"
        cLabel="Old Password"
        class="input-group--focused"
        @click:append="showOldPassword = !showOldPassword"      >
      </CTextField>

      <!-- <v-text-field
        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        validate-on-blur
        :type="showOldPassword ? 'text' : 'password'"
        v-model="oldPassword"
        label="Old Password"
        class="input-group--focused"
        @click:append="showOldPassword = !showOldPassword"
      ></v-text-field> -->

      <CTextField 
       changed
        height="48px" 
        hide-details 
        :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        validate-on-blur
        :type="showPassword1 ? 'text' : 'password'"
        v-model="password"
        cLabel="New Password"
        class="input-group--focused mt-6" 
        @click:append="showPassword1 = !showPassword1">
      </CTextField>

      <CTextField 
       changed
        height="48px" 
        hide-details 
        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min, this.password === this.password2 || 'Password must match']"
        validate-on-blur
        :type="showPassword2 ? 'text' : 'password'"
        v-model="password2"
        cLabel="Confirm New Password"
        class="input-group--focused  mt-6"
        @click:append="showPassword2 = !showPassword2">
      </CTextField>
      

      <!-- <v-text-field
        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min, this.password === this.password2 || 'Password must match']"
        validate-on-blur
        :type="showPassword2 ? 'text' : 'password'"
        v-model="password2"
        label="Confirm New Password"
        class="input-group--focused"
        @click:append="showPassword2 = !showPassword2"
      ></v-text-field> -->
      <v-btn :disabled="!valid" class="mt-10 mb-4" @click="changePass()" color="primary" value="log in">Change Password</v-btn>
    </v-form>

    </div>
    <div class="mx-16 px-16">
      <img class="ml-13" src="@/assets/img/change-password.png" />

    </div>
  </div>
</template>
<script>
import { changePassword } from "@/api/users";
import CTextField from '@/components/Vuetify/CTextField.vue'

export default {
  name: "ChangePassword",
  components:{CTextField},
  data() {
    return {
      valid: true,
      userId: this.$store.state.UserId,
      showPassword1: false,
      showPassword2: false,
      showOldPassword: false,
      oldPassword: "",
      password: "",
      password2: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        min: (v) => v.length >= 8 || "Min 8 characters",
        // passwordMatch: () => selectedUser.password != selectedUser.password2 || 'Passwords doesn\'t match',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    changePass() {
      const _this = this;
      changePassword(
        this.userId,
        this.oldPassword,
        this.password,
        this.password2,
        function () {
          _this.oldPassword = "";
          _this.password = "";
          _this.password2 = "";
        },
        function (err) {
          if (err.status == 400) {
            var msg = "";
            for (const k of Object.keys(err.data)) {
              msg += k + ":" + err.data[k][0];
            }
            _this.$root.notify.show({ message: msg, type: "error" });
          } else {
            _this.$root.notify.show({ message: "Internal Error!", type: "error" });
          }
        },
        "Password changed successfully"
      );
    },
  },
  created() {},
};
</script>
